import { DNA, DNAProps } from "react-loader-spinner";

export default function LoadingDNA(
  props: Omit<DNAProps, "ariaLabel" | "wrapperClass">
) {
  return (
    <DNA
      {...props}
      ariaLabel="dna-loading"
      wrapperClass="dna-wrapper"
      visible={props.visible ?? true}
      height={props.height ?? "50%"}
      width={props.width ?? "50%"}
    />
  );
}
